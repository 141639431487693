<template>
  <div class="index-container">
    <div class="calendar">
      <div class="schedule">
        <checkSchedule ref="checkSchedule" @select="dateSelect" />
      </div>
      <div class="setting">
        <scheduleTimes ref="scheduleTimes" />
      </div>
    </div>
    <div class="dataList">
        <checkList ref="checkList"  :filter="filter" view="Schedule"/>
    </div>
  </div>
</template>
<script>
import checkList from "./components/checkList.vue";
import checkSchedule from "./components/checkSchedule.vue";
import scheduleTimes from "./components/scheduleTimes.vue";
export default {
  components: { checkSchedule, scheduleTimes,checkList },
  data() {
    return {
      filter:{
        search: "",
        status: "Appoint",
        source: "",
        dateType: "appoint",
        beginDate:"",
        endDate:"",
      }
    };
  },
  methods: {
    dateSelect(v) {
      this.$nextTick(()=>{
        this.$refs.scheduleTimes.loadTimes(v.day,v.times);
      })
      this.filter.beginDate=v.day;
      this.filter.endDate=v.day;
    },
  },
  mounted() {
    // this.handleQuery();
  },
};
</script>
<style lang="scss" scoped>
.index-container {
   display: flex;
    flex-direction: column;
    align-items: flex-start;
  .calendar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .schedule {
      width: 65%;
    }
    .setting {
      flex: 1;
      align-content: flex-start;
    }
  }
  .dataList {
    margin-top: 10px;
    width: 100%;
  }
}
</style>